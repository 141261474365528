<template>
    <v-dialog :value="value" persistent width="70%">
        <v-card>
            <v-toolbar flat dense>
                <v-layout d-flex justify-end>
                    <v-btn icon @click="close">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-layout>
            </v-toolbar>

            <div class="pa-3">
                <v-layout d-flex justify-center v-if="!loadedCart.length && !item && !loaded">
                    <v-progress-circular color="primary" size="100" indeterminate class="my-10"/>
                </v-layout>
                <v-layout d-flex justify-center v-else-if="!loadedCart.length && !item && loaded">
                  <div>Es wird kein passende Artikel gefunden</div>
                </v-layout>
                <v-simple-table v-else>
                    <thead>
                    <tr>
                        <th>Artikelnummer</th>
                        <th>Hersteller</th>
                        <th>Beschreibung</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(article, key) in item.article" :key="key" @click="select(key)">
                        <td>{{ article.data.no }}</td>
                        <td>{{ article.brand.name }}</td>
                        <td>{{ article.data.name }}</td>
                        <td>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "OrylCartLoader",
        props: {
            value: {
                type: Boolean,
                default: false,
            }
        },
        data: function () {
            return {
                item: null,
                loaded: false,
            }
        },
        computed: {
            loadedCart: function () {
                return this.$store.state.CartStore.loadedCart;
            },
        },
        methods: {
            select: function (index) {
                this.$store.dispatch('CartStore/addLoadedItem', {
                    amount: this.item.amount,
                    article: this.item.article[index],
                });
                this.item = null;
                this.process();
            },
            process: function () {
                this.$store.dispatch('CartStore/popLoadedCart')
                .then((response) => {
                    if (!Object.keys(response).length) {
                        this.close();
                        return;
                    }

                    if (response.article.length === 1) {
                        this.$store.dispatch('CartStore/addLoadedItem', {
                            amount: response.amount,
                            article: response.article.shift(),
                        });
                        return this.process();
                    }
                    this.item = response;
                });
            },
            close: function () {
                this.$emit('input', false);
            }
        },
        watch: {
            loadedCart: {
                handler() {
                    if (this.loadedCart.length > 0 && !this.loaded) {
                        this.loaded = true;
                        this.process();
                    } else {
                      this.loaded = true;
                      this.item = null;
                    }
                }
            }
        },
        mounted() {
            this.item = null;
        }
    }
</script>

<style scoped>

</style>
