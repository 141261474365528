<template>
    <oryl-container>
        <v-row>
            <v-col class="px-1 py-2">
              <v-layout d-flex justify-center>
                <v-col cols="12" sm="12" md="6" lg="4">
                  <v-stepper v-model="step"  class="ma-0 pa-0" alt-labels>
                      <v-stepper-header>
                          <v-stepper-step step="1" :complete="step > 1" editable>{{$t('cart.name')}}</v-stepper-step>
                          <v-divider/>
                          <v-stepper-step step="2" :complete="step > 2">{{$t('cart.checkout')}}</v-stepper-step>
                      </v-stepper-header>
                  </v-stepper>
                </v-col>
              </v-layout>
            </v-col>
        </v-row>
        <v-row v-show="step === 1">
            <v-col class="px-1 py-0" v-show="cart.length">
                <oryl-shop-result :item="item.article" v-for="(item, key) in cart" :key="key" class="mb-2"/>
            </v-col>
            <v-col v-show="!cart.length">
                <v-layout d-flex justify-center>
                    <div class="headline">{{$t('cart.empty')}}</div>
                </v-layout>
            </v-col>
            <v-col cols="12" md="12" sm="12" lg="5" xl="4" class="px-1 py-0">
                <oryl-sticky>
                    <v-layout d-flex column>
                        <v-card tile class="mb-3" v-if="cartSum">
                            <v-layout d-flex column>
                                <v-layout d-flex justify-space-between class="pa-3 text--secondary">
                                    <div>{{$t('cart.netto')}}</div>
                                    <div>{{(cartSum - deposit) | currency}}</div>
                                </v-layout>
                                <v-layout d-flex justify-space-between class="pa-3 text--secondary">
                                    <div>Pfand</div>
                                    <div>{{deposit | currency}}</div>
                                </v-layout>
                                <v-divider/>
                                <v-layout d-flex justify-space-between class="pa-3 headline">
                                    <div>{{$t('cart.sum')}}</div>
                                    <div>{{cartSum | currency}}</div>
                                </v-layout>
                            </v-layout>
                        </v-card>
                        <v-card tile class="pa-3">
                            <v-layout d-flex justify-space-around>
                                <v-btn large rounded elevation="3" color="green" :disabled="!cart.length" @click="step++">
                                    <v-icon color="white" large>mdi-cart-arrow-up</v-icon>{{$t('cart.checkout')}}
                                </v-btn>
                                <v-btn large rounded elevation="3" color="red" :disabled="!cart.length" @click="deleteAll">
                                    <v-icon color="white" large>mdi-delete-sweep</v-icon>{{$t('cart.delete')}}
                                </v-btn>
                            </v-layout>
                            <v-divider class="mt-3"/>
                          <v-text-field outlined hide-details="auto" :label="$t('shop.search')" append-icon="mdi-magnify" @keydown.enter="loadOne" @click:append="loadOne" v-model="searchInput" class="ma-3"/>
                          <v-row>
                            <v-col cols="6">
                              <v-file-input v-model="file" :label="$t('cart.file')" accept=".csv" clearable show-size/>
                            </v-col>
                            <v-col cols="6">
                              <v-select v-model="trenn" :items="trennzeichen" :label="$t('cart.delimiter')"/>
                            </v-col>
                          </v-row>
                            <v-layout d-flex justify-space-around>
                              <v-row>
                                <v-col cols="12">
                                  <v-btn block outlined color="black" @click="loadCart" :disabled="!file" class="no-uppercase">
                                      <v-icon color="info" large>mdi-upload</v-icon>{{$t('cart.csv.upload')}}
                                  </v-btn>
                                  </v-col>
                                  <v-col cols="12">
                                  <v-btn block outlined color="black" @click="saveCart" :disabled="!cart.length" class="no-uppercase">
                                      <v-icon color="info" large>mdi-download</v-icon>{{$t('cart.csv.download')}}
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-layout>
                        </v-card>
                    </v-layout>
                </oryl-sticky>
            </v-col>
        </v-row>
        <v-row v-if="step === 2" align="center" justify="center">
            <v-col cols="12" sm="12" md="8" lg="6" class="pa-1">
                <v-card width="100%" class="pa-3">
                    <v-layout d-flex column>
                        <v-form ref="form" v-model="valid" @submit.prevent="checkout">
                            <v-layout d-flex>
                                <v-radio-group row v-model="order.type">
                                    <v-radio value="bestellung" :label="$t('cart.order')" :disabled="tecdoconly"/>
                                    <v-radio value="anfrage" :label="$t('cart.request')"/>
                                </v-radio-group>
                                <v-tooltip right v-if="tecdoconly">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">
                                            mdi-information
                                        </v-icon>
                                    </template>
                                    <span>{{$t('cart.onlyrequest')}}</span>
                                </v-tooltip>
                            </v-layout>
                            <v-text-field :label="$t('cart.crmId')" v-model="order.crmId"/>
                            <v-textarea :label="$t('cart.note')" v-model="order.note"/>
                          <div class="text-center">
                            <v-btn large rounded elevation="3" color="green" type="submit" :loading="loading" :disabled="loading || !valid">
                                <v-icon color="white" large>mdi-cash</v-icon> {{$t('cart.checkout')}}
                            </v-btn>
                          </div>
                        </v-form>
                    </v-layout>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar absolute bottom :value="success" color="success">
            <v-layout d-flex justify-center>
                {{$t('cart.checkedout')}}
            </v-layout>
        </v-snackbar>

        <oryl-cart-loader v-model="loadedCart" v-if="loadedCart"/>

        <oryl-to-top/>
    </oryl-container>
</template>

<script>
    import OrylContainer from "../../components/OrylContainer";
    import OrylSticky from "../../components/OrylSticky";
    import OrylToTop from "../../components/OrylToTop";
    import OrylShopResult from "../../components/OrylShopResult";
    import OrylCartLoader from "../../components/OrylCartLoader";

    export default {
        name: "Cart",
        components: {OrylCartLoader, OrylShopResult, OrylToTop, OrylSticky, OrylContainer},
        data: function () {
            return {
                rules: [
                  value => (value && value.length >= 4) || this.$t('shop.error.minsearch')
                ],
                step: 1,
                order: {
                    type: 'bestellung',
                    note: '',
                    crmId: ''
                },
                loading: false,
                success: false,
                valid: true,
                file: null,
                loadedCart: false,
                trenn: ';',
                trennzeichen: [',', ';'],
                searchInput: ''
            }
        },
        computed: {
            cart: function () {
                return this.$store.state.CartStore.cart;
            },
            cartSum: function () {
                return this.$store.getters['CartStore/sum'];
            },
            tecdoconly: function () {
                return this.cart.every((item) => {
                    return item.article.ids.webiscoId === 0;
                });
            },
            deposit: function () {
                if (!this.cart) {
                    return;
                }

                return this.cart.reduce(function (prev, current) {
                    return prev + current.article.deposit * current.amount;
                }, 0)
            }
        },
        methods: {
            reset: function () {
              this.searchInput = '';
              this.searchTypeRadio = 'full';
            },
            loadOne: function() {
              this.$store.dispatch('CartStore/loadOne', {name: this.searchInput});
              this.searchInput = '';
              this.loadedCart = true;
            },
            loadCart: function () {
                if (!(this.file instanceof File)) {
                    return;
                }

                const reader = new FileReader();
                reader.onload = (e) => {
                    this.$store.dispatch('CartStore/loadCart', {file: e.target.result.split(',')[1], trenn: this.trenn});
                    this.file = null;
                };

                this.loadedCart = true;
                reader.readAsDataURL(this.file);
            },
            saveCart: function () {
                this.$store.dispatch('CartStore/saveCart', {trenn: this.trenn})
                .then((response) => {
                    const blob = new Blob([atob(response.csv)], {type: 'text/csv'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'cart.csv';
                    link.target = '_blank';
                    link.click();
                    URL.revokeObjectURL(link.href);
                });
            },
            deleteAll: function () {
                this.$store.dispatch('CartStore/removeAll');
            },
            checkout: function () {
                this.loading = true;

                this.$store.dispatch('CartStore/checkout', this.order)
                .then(() => {
                    this.step = 1;
                    this.success = true;
                })
                .finally(() => {
                    this.loading = false;
                });
            }
        },
        mounted() {
            this.order.type = this.tecdoconly ? 'anfrage' : 'bestellung';
        }
    }
</script>

<style scoped>
.no-uppercase {
  text-transform: none;
}
</style>